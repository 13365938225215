// font variables
$font: 'Montserrat', sans-serif;
$font-book: 'Montserrat', sans-serif;

// color variables
$normal: #004d8f;
$indroduction-color: $normal;
$placeholder-color: #b9b9b9;
$description-color: #807878;
$white-color: #fff;
$black-color: #000;
$link-color: #39b1b7;
$h3-color: $link-color;
$question-color: $link-color;
$title-background: rgba($normal, 0.1);
$orange-color: #ff6600;
$danger-color: #e40e1a;
$dark-color: #29373f;
$orange: #fd6401;
$blueSky: #81aad0;
