@import "variables";
@import "settings";
@import 'foundation';

.content-editor {
    font-family: $font-book;
    font-weight: 300;
    font-size: rem-calc(18);
    line-height: 1.6666;
    color: #232323;

    >*:first-child {
        font-size: rem-calc(24);
        line-height: 1.4583;
        color: $normal;
        font-weight: 400;
        margin-bottom: rem-calc(30);

        &:first-letter {
            font-size: rem-calc(72);
            line-height: 50px;
            float: left;
            margin-top: 0px;
            margin-right: 3px;
            margin-top: 10px;
        }
    }

    p {
        margin-bottom: rem-calc(30);
    }

    strong {
        font-family: $font;
        font-weight: 400;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: rem-calc(20);
        color: #39b1b7;
        font-weight: 700;
        margin-bottom: 5px;
    }

    a {
        color: #232323;
        text-decoration: underline;
    }

    ul {
        list-style: none;
        margin: 0 0 rem-calc(30);

        li {
            padding-left: 25px;
            position: relative;

            &:before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
                background-color: $black-color;
                top: 12px;
                left: 0 ;
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: olNumber;
        margin-bottom: rem-calc(30);

        > li {
            padding-left: 30px;
            position: relative;

            &:before {
                counter-increment: olNumber;
                content: counter(olNumber)".";
                position: absolute;
                left: 5px;
                font-weight: 700;
                font-family: $font;
            }
        }
    }

    blockquote {
        border: 0;
        color: $orange-color;
        font-size: rem-calc(36);
        position: relative;
        padding: 0 0 0 110px;
        line-height: 1.1111;
        margin-bottom: 30px;

        q {
            &:before {
                font-size: rem-calc(300);
                color: rgba($orange-color, .1);
                position: absolute;
                left: 0;
                top: 110px;
                line-height: 0;
            }
        }
    }

    table {
        margin-bottom: rem-calc(30);
        width: 100%;
        border: 1px solid #b1b1af;

        th {
            text-align: left;
            background-color: #b1b1af;
        }

        td,
        th {
            padding: rem-calc(13) rem-calc(20);
            width: 1%;
            border: 1px solid #b1b1af;
        }
    }
}

hr {
    margin-top: rem-calc(90);
    margin-bottom: rem-calc(90);
    border: 0;
}
